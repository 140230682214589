.navbar {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
}

.navbar .leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 150px;
}

.navbar .leftSide img {
  width: 100px;
}

.navbar .rightSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  margin: 20px;
}


/* @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 70px;
    }
    .navbar .leftSide {
      padding-left: 50px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .navbar .rightSide a {
      display: none;
    }
  
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 50px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    
    .hiddenLinks {
      display: none;
    }
  } */