/* .home {
  width: 631px;
  height: 670px;
  position: static;
  

} */

.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row1 {
  background: #E8E8E8;
}

.headerContainer h1 {
  font-size: 35px;
  /* text-align: left; */
  /* color: #331F98; */
  /* font-size: 50px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;


}

.headerContainer {
  display: flex;
  justify-content: center;


}

.intro {
  font-size: 15px;
  justify-content: center;
  display: flex;
  padding: 50px;

}

.intro p {
  font-size: 16px;
  max-width: 50em;
  line-height: 1.6;
  margin: 0 auto;
  padding: 20px;
}


/* .row3 {
  padding: 20px;
} */




.network h2 {
  font-size: 18px;
  color: #331F98;
  text-decoration: underline;
}

.network h3 {
  font-size: 17px;
  color: #331F98;
  padding-top: 15px;

}

.network p {
  font-size: 15px;
  color: black;
}

.home .video-fluid {
  max-width: 50%;
  max-height: 100%;

}