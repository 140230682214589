.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 100px;
    width: 200px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.box {
    width: 20px;
    height: 20px;
    background-color: #6366F1;
    animation: bounce 0.6s infinite cubic-bezier(0.5, 0.05, 0.5, 1.5) alternate;
}

.box:nth-child(2) {
    animation-delay: 0.2s;
}

.box:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50px);
    }
}