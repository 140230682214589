.footer {
    background-color: #E8E8E8;
    /* width: 1920px; */
    /* height: 134px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

}

.footer .socials p {
    font-weight: bolder;
    color: #6c757d;

}

.footer .socials {
    justify-content: space-between;
    color: #6c757d;

}

.icon {

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #6c757d;



}



.privacy {

    display: flex;
    text-align: left;
    justify-content: left;
    flex-direction: column;
    color: #6c757d;

}



.privacy h3 {

    font-size: 30px;

}



.logo img {

    width: 134px;

    height: 90px;

}



/* Add this CSS inside your mobile media query (max-width: 767px) */


.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .footer p {
    font-size: 16px;
    max-width: 35%;
    line-height: 1.6;

} */

.address {
    display: flex;
    justify-content: center;
    text-align: center;

}

.address p {
    max-width: 35%
}