.row3 p {
    font-size: 16px;
    max-width: 25em;
    line-height: 1.6;
}

.network {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}